<template>
    <AppPanel :location="['Clientes', 'Setor']" subtitle="Listagem de setores">
        <template #content>
            <AppPaginatedGrid ref="grid" :service="service" permission="estrutura_setor" newPath="/department/new"
                editPath="/department/" :expander="true" subtitle="Setor" tooltip="Cadastro de Setores"
                nomeTelaDoManual="department-list" origemHistoricoAcao="/customer/departments">
                <template #columns>
                    <Column :sortable="true" field="id" header="Cod." sortField="customer_departments.id"></Column>
                    <Column :sortable="true" field="name" header="Setor" sortField="customer_departments.name"></Column>
                    <Column :sortable="true" field="cliente.name" header="Cliente" sortField="cliente.name"></Column>
                    <Column header="Codigo SOC" :sortable="true" sortField="customer_departments.codigoSoc">
                        <template #body="{ data }">
                            <div v-if="!data.cliente?.integracaoSoc" class="field mt-1 mb-0" style="text-align: center">
                                {{ 'Sem integração' }}
                            </div>
                            <div v-if="data.cliente?.integracaoSoc && !data.codigoSoc" class="field mt-1 mb-0"
                                style="text-align: center">
                                <i v-tooltip="'Cadastro sem codigo do SOC'" class="pi pi-exclamation-circle"
                                    style="font-size: 2rem; color: #ff9900; cursor: pointer" @click="logs(data)" />
                            </div>
                            <div v-if="data.cliente?.integracaoSoc && data.codigoSoc" class="field mt-1 mb-0"
                                style="text-align: center">
                                {{ data.codigoSoc }}
                            </div>
                        </template>
                    </Column>
                </template>
                <template v-slot:expansion="slotProps">
                    <div class="m-4 expander">
                        <div class="grid">
                            <div class="col-3">
                                <strong class="block my-2">Descrição eSocial</strong>
                                {{ slotProps.data.description }}
                            </div>
                            <div class="col-3">
                                <strong class="block my-2">Observação ASO</strong>
                                {{ slotProps.data.asoObservation }}
                            </div>
                            <div class="col-3">
                                <strong class="block my-2">Criado em</strong>
                                {{ $filters.formatDate(slotProps.data.createdAt) }}
                            </div>
                            <div class="col-3">
                                <strong class="block my-2">Alterado em</strong>
                                {{ $filters.formatDate(slotProps.data.updatedAt) }}
                            </div>
                        </div>
                    </div>
                </template>
            </AppPaginatedGrid>
            <AppLogErroDialog v-if="showLogsDialog" v-model:visible="showLogsDialog" title="Logs para o setor"
                origem="CADASTRO_SETOR" v-model:origemId="record.id" @onClose="showLogsDialog = false">
            </AppLogErroDialog>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';

export default {
    data() {
        return {
            tenantCustomers: [],
            service: null,
            showLogsDialog: false
        };
    },
    mounted() {
        this.tenantCustomers = getCurrentCustomers();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new BaseService('/customer/departments');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        async logs(data) {
            this.record = data;
            this.showLogsDialog = true;
        }
    }
};
</script>
<style scoped>
.expander {
    background: #ccccff29;
    margin: 0px !important;
    padding: 5px;
    border-radius: 5px;
}
</style>
